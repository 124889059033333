var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('div',{staticClass:"modal fade",attrs:{"id":"modalAreaLaboratorio","tabindex":"-1","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"card shadow-none"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-lg-12 col-md-12 col-sm-12"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Nombre")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.areas.nombre),expression:"areas.nombre"}],staticClass:"form-control form-control-sm",class:{
                    'is-invalid':
                      _vm.$v.areas.nombre.$error && _vm.$v.areas.nombre.$dirty,
                  },attrs:{"type":"text"},domProps:{"value":(_vm.areas.nombre)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.areas, "nombre", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-12 col-md-12 col-sm-12"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Descripcion")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.areas.descripcion),expression:"areas.descripcion"}],staticClass:"form-control form-control-sm",class:{
                    'is-invalid':
                      _vm.$v.areas.descripcion.$error &&
                      _vm.$v.areas.descripcion.$dirty,
                  },attrs:{"type":"text"},domProps:{"value":(_vm.areas.descripcion)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.areas, "descripcion", $event.target.value)}}})])])])])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-success btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar"),_c('i',{staticClass:"fa fa-save fa-fw"})]),_c('button',{staticClass:"btn btn-light btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.cerrar()}}},[_vm._v(" Cerrar"),_c('i',{staticClass:"fa fa-times-circle fa-fw"})])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v(" Registro de Areas de Laboratorio ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header bg-light text-dark"},[_c('i',{staticClass:"fa fa-book fa-fw text-primary"}),_vm._v("Datos ")])}]

export { render, staticRenderFns }